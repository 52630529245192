<template>
    <div class="max-w-5xl mx-auto">
        <div class="grid grid-cols-3 gap-4">
            <div class="bg-white shadow-md rounded-md mt-2 p-4 gap-4">
                <div class="relative w-20 h-20 mx-auto border border-slate-700 rounded-full flex items-center justify-center">
                   <div class="absolute text-xl text-brand font-semibold uppercase" v-if="imageName">
                       <span>{{ imageName.split(' ')[0][0] }}</span> 
                       <span v-if="imageName.split(' ')[1]">{{ imageName.split(' ')[1][0] }}</span>
                   </div>
                </div>
                <hr class="mt-2 mb-2">
                 <div class="text-xl">{{ imageName }}</div>
            </div>
            <div class="col-span-2 bg-white shadow-md rounded-md mt-2 p-4 gap-4">
                <div class="text-left flex flex-col gap-4">
                    <div> <strong class="uppercase"> Name : </strong>{{ showData.name }}</div>
                    <div><strong class="uppercase">Phone : </strong>{{ showData.phone }}</div>
                    <div><strong class="uppercase"> Email : </strong>{{ showData.email }}</div>
                    <div><strong class="uppercase"> Gender : </strong>{{ showData.gender == 1 ? 'Male': showData.gender == 2 ? 'Female':''  }}</div>
                    <div> <strong class="uppercase"> BMDC : </strong> {{ showData.bmdc }}</div>
                    <div> <strong class="uppercase"> Medical : </strong>{{ showData.medical }}</div>
                    <div> 
                        <router-link :to="{ name:'UserEdit', params:{ id:this.$route.params.id}}">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                            </svg>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {

            }
        }
    },
    created(){
        this.$store.dispatch('users/userShow',{
            id: this.$route.params.id
        })
    },
    computed:{
        showData(){
            return this.$store.state.users.user_show || '';
        },

        imageName(){
            return this.showData.name || '';
        }

    }
}
</script>